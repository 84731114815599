import React from "react";

import Layout from "../../../components/base/layout";
import SEO from "../../../components/base/seo";

const SEOptimizedBlogSeries = () => (
  <Layout>
    <SEO title="SEOptimized Blog Series" />
    <div className="container">
      <section className="section-checkout-form page-heading">
        <div className="checkout-product-heading">
          <h2>SEOptimized Blog Series | 3 Blogs | $1,500 per series</h2>
        </div>
        <div className="checkout-form-details">
          <div className="checkout-desc">
            <p>
              SEO optimized blog content is important for a website because it
              helps to increase visibility and traffic to the website. It allows
              search engines to easily find and rank your content in their
              search results, which means more potential customers will see your
              website and be more likely to visit it.<br></br>SEO optimized
              content also helps to build trust and authority in your website by
              providing useful, relevant content to your readers. This is
              important because it helps to create a positive reputation for
              your website, which can lead to more customers and more sales.
              Building a regular blog series can help engage your audience over
              a longer stretch of time, creating multiple website visits and
              increasing your chances of winning them over.
            </p>
            <p></p>
            <h5 className="heading">INCLUDED:</h5>
            <ul>
              <li>
                Series topic planning, researching*, writing, & editing with SEO
                top of mind for three (3) blogs
              </li>
              <li>
                Creation of 1 x social caption & 1 social graphic for 2-3 key
                channels per blog to share them
              </li>
              <li>
                Sourcing and resizing of images for the blog header and blog
                body for each blog
              </li>
              <li>Publishing of the blogs (3) on your website**</li>
            </ul>
            <p className="text-small">
              <i>
                Note: *Depending on the level of expertise needed for your
                content, the research phase may need internal support from your
                team – we’re a very well-rounded team but are sadly not actual
                rocket scientists or brain surgeons.<br></br>**This is
                applicable for standard low-code platforms such as WordPress,
                Squarespace, Shopify, WebFlow, HubSpot, etc. If the blog needs
                to be hand-coded, there will be an additional fee for our
                developers' hours (and also, reach out to us about updating that
                – you should be able to update your blog content without getting
                a developer involved).
              </i>
            </p>
            <p className="text-red">
              This can only be purchased as an add-on to one of our other SEO
              packages. Please return to the SEO packages page and select this
              add-on during the checkout process.
            </p>
          </div>
        </div>
      </section>
    </div>
  </Layout>
);

export default SEOptimizedBlogSeries;
